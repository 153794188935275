.mainest {
  background: url("../../assets/svg/bg-logo-full.svg") bottom left no-repeat;
  background-size: 30%;
  overflow: hidden;
}

.main {
  margin-top: 1rem;
  max-width: 50rem;
  margin-inline: auto;
  margin-bottom: calc(8rem + 3vw);
  min-height: 70vh;
}

.main h1 {
  color: #01b2ff;
  font-weight: 700;
  font-size: 4rem;
  text-align: left;
}

.main h1 .black {
  color: black;
  font-weight: 400;
}

.get_info {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  flex: 1;
}

@media screen and (max-width: 950px) {
  .mainest {
    background-size: 40%;
  }
  .main {
    margin-inline: clamp(15px, 7vw, 500px);
  }

  .main h1 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 750px) {
  .mainest {
    background-size: 55%;
  }
  .main h1 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 650px) {
  .mainest {
    background-size: 70%;
    background-position: center left;
  }
  .main h1 {
    font-size: 2rem;
    max-width: 25rem;
    margin-inline: auto;
  }

  .get_info {
    margin-top: 2rem;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }
}
