.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em 0.2em;
  background-color: white;
  outline: none;
  border: 2px solid black;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: 650;
  font-size: clamp(1.1rem, 2vw, 1.15rem);
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

.button:hover {
  transform: scale(1.04);
}

.button.disabled:hover {
  transform: none;
  cursor: auto;
}

.text {
  margin-inline: 0.5em;
  white-space: nowrap;
  color: black;
}

.button.disabled .text {
  color: rgb(153, 153, 153);
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.9em;
  height: 1.9em;
  line-height: 0;
  background-color: black;
  color: white;
  border-radius: 50%;
}

.spinner {
  background-color: black;
  position: absolute;
  width: 0.7em;
  height: 0.7em;
  border: 0.2em solid transparent;
  border-top: 0.2em solid white;
  border-radius: 50%;
  box-sizing: content-box;
}
