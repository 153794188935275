.main {
  margin-top: calc(-6vw + -20px);
  overflow: hidden;
}

.bg_image {
  width: 100%;
  height: clamp(40rem, 50vw, 56rem);
  background-image: url("../../assets/images/bg_feet_2500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  will-change: background-image;
  transition: background-image 0.1s ease;
}

.question {
  font-weight: 400;
  font-size: clamp(1.65rem, 4vw, 4.5rem);
  margin-left: calc(6vw + 5px);
  padding-top: clamp(6rem, 10vw, 25rem);
  color: #01b2ff;
}

.answer {
  display: block;
  font-weight: 600;
  font-size: clamp(2.6rem, 6.5vw, 7.3rem);
  color: black;
}

.marquee h2 {
  text-transform: uppercase;
  font-size: clamp(2rem, 4vw, 3rem);
  letter-spacing: clamp(0.1rem, 1.5vw, 0.7rem);
  padding-inline: clamp(1.6rem, 4.2vw, 2.2rem) clamp(1.3rem, 3.6vw, 1.8rem);
  text-align: center;
  color: #01b2ff;
  margin: 2vw 0;
}

.marquee img {
  width: clamp(1.5rem, 3vw, 2rem);
  height: auto;
}

@media screen and (max-width: 2000px) {
  .bg_image {
    background-image: url("../../assets/images/bg_feet_2000.jpg");
  }
}

@media screen and (max-width: 1600px) {
  .bg_image {
    background-image: url("../../assets/images/bg_feet_1600.jpg");
  }
}

@media screen and (max-width: 1080px) {
  .bg_image {
    background-image: url("../../assets/images/bg_feet_1080.jpg");
  }
}

@media screen and (max-width: 720px) {
  .bg_image {
    background-image: url("../../assets/images/bg_feet_720.jpg");
  }
}

@media screen and (max-width: 414px) {
  .bg_image {
    background-image: url("../../assets/images/bg_feet_414.jpg");
  }
}
