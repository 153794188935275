.container {
  margin-inline: auto;
  width: 97vw;
  border-radius: 1.5rem;
  height: 32rem;
  padding: 1.3rem;
  background-color: rgba(245, 245, 245, 1);
  overflow-x: none;
}

.title {
  text-transform: uppercase;
  padding-top: clamp(0.1rem, 10vw, 0.8rem);
  padding-bottom: clamp(1.3rem, 10vw, 1.8rem);
  text-align: center;
  font-weight: 800;
  font-size: clamp(1.5rem, 3vw, 2.1rem);
  letter-spacing: 0.4rem;
}

.title span {
  text-align: center;
  padding-top: 5px;
  font-weight: 400;
  color: rgba(1, 178, 255, 1);
}

.slider {
  width: 90%;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.opinion {
  position: absolute;
  border-radius: 40px;
  width: 300px;
  height: 210px;
  padding: 37px 30px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.2px;
}

.content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.name {
  text-align: right;
  font-size: 27px;
  margin-top: 30px;
}

.opinion[style*="z-index: 5"] {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  width: 300px;
  height: 300px;
  font-weight: 400;
  padding: 25px 30px 0 30px;
  font-size: 18px;
  line-height: 22px;
  -webkit-box-shadow: 0px 20px 29px -18px rgba(59, 175, 241, 1);
  -moz-box-shadow: 0px 20px 29px -18px rgba(59, 175, 241, 1);
  box-shadow: 0px 20px 29px -18px rgba(59, 175, 241, 1);
}

.opinion[style*="z-index: 5"] .content {
  display: block;
  -webkit-line-clamp: none;
  -webkit-box-orient: none;
}

.opinion[style*="z-index: 5"] .name {
  text-align: right;
  font-size: 27px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 21rem;
  position: absolute;
  z-index: 5;
}

.slider button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1.3rem;
  width: 2rem;
  height: 2rem;
  border: 0.15rem solid black;
  border-radius: 50%;
  background-color: none;
  cursor: pointer;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
  line-height: clamp(10px, 1vw, 10px);
  box-sizing: content-box;
}

.slider button:hover {
  transform: scale(1.08);
  background-color: whitesmoke;
}

.left_arrow {
  rotate: 180deg;
}

@media (max-width: 1200px) {
  .container {
    height: 33.5rem;
  }
  .title {
    white-space: pre;
    letter-spacing: 0.3rem;
  }

  .title .first {
    display: block;
  }
}

@media (max-width: 600px) {
  .container {
    height: 34.5rem;
    padding: 0.8rem;
  }

  .title {
    letter-spacing: 0.2rem;
  }
  .title .second {
    display: block;
    line-height: 1rem;
  }
}
