.contact_info {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1.8rem;
}

.info,
.hint {
  text-align: justify;
}

.info {
  font-size: 1.1rem;
  font-weight: 500;
}

.hint {
  font-size: 1.1rem;
  font-weight: 200;
}

.phone label,
.mail label {
  color: #adadad;
}

.phone,
.mail {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.phone {
  margin-bottom: -1rem;
}

.phone img,
.mail img {
  width: 2rem;
  height: auto;
}

.phone button,
.mail button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 0.4rem;
}

.phone a,
.mail a {
  white-space: nowrap;
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 650px) {
  .contact_info {
    max-width: 25rem;
    width: 100%;
    margin-inline: auto;
  }
}
