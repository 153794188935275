.contact_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 0.7rem;
  max-height: 30rem;
}

.vis_label_on {
  color: black;
}

.vis_label_off {
  color: #d9d9d9;
}

.switch {
  margin-left: 0.5rem;
}

.hid_label {
  display: none;
  visibility: hidden;
}

.video_call {
  white-space: nowrap;
}

.contact_form input {
  width: 100%;
  padding: 0.7rem;
  border-radius: 1rem;
  border: 2px solid black;
}

.contact_form textarea {
  padding: 0.7rem;
  border-radius: 15px;
  resize: none;
  border-width: 2px;
  height: 8rem;
  border: 2px solid black;
}

.contact_form button {
  align-self: flex-end;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-inline: auto;
  font-weight: 600;
  width: 400px;
  height: 190px;
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  text-align: left;
  line-height: 18px;
}

.modal button {
  font-size: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: auto;
}

.modal p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 650px) {
  .contact_form {
    max-width: 25rem;
    width: 100%;
    margin-inline: auto;
  }
}
