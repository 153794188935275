.step {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;
}

.step img {
  width: 100%;
  height: auto;
}

.title {
  padding: 0.5rem;
  margin-inline: 1rem;
  border-radius: 2rem;
  font-weight: 700;
  font-size: 1.1rem;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(1, 178, 255, 1);
  color: white;
}

.text {
  font-size: 0.8rem;
  margin-inline: 1.2rem 0.3rem;
  font-weight: 500;
}

@media screen and (max-width: 950px) {
  .step {
    max-width: 40%;
  }
}

@media screen and (max-width: 650px) {
  .step {
    justify-content: center;
    max-width: 60%;
  }
  .title {
    margin-inline: 0;
  }
  .text {
    margin-inline: 0;
  }
}

@media screen and (max-width: 480px) {
  .step {
    max-width: 70%;
  }
}

@media screen and (max-width: 350px) {
  .step {
    max-width: 90%;
  }
}
