.our_team {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.ihor,
.hanna {
  max-width: 25rem;
}

.our_team img {
  width: 100%;
  height: auto;
}

.figcaption {
  font-size: 1.7rem;
  text-transform: uppercase;
}

.ihor .figcaption {
  text-align: right;
  margin-right: 3rem;
}

.hanna {
  margin-top: 6rem;
}

.hanna .figcaption {
  margin-left: 2rem;
}

.figcaption .name {
  font-weight: 700;
}

.figcaption .position {
  font-size: 1.1rem;
  display: block;
  color: rgba(1, 178, 255, 1);
  text-transform: none;
  white-space: nowrap;
}

@media (max-width: 950px) {
  .figcaption {
    font-size: 1.4rem;
  }
}

@media (max-width: 650px) {
  .our_team {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    margin-top: 3rem;
  }

  .hanna {
    margin-top: 0;
  }
}
