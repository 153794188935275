.header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100vw;
  padding: 0.7rem 0;
}

.header_bg {
  box-shadow: 0px 0px 27px -7px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-box-shadow: 0px 0px 27px -7px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 27px -7px rgba(0, 0, 0, 0.22);
  -webkit-backdrop-filter: blur(10px);
}

.hum_open {
  background-color: rgba(255, 255, 255, 1);
  padding-bottom: 50px;
}

.nav {
  display: flex;
  align-items: center;
  margin-inline: clamp(15px, 7vw, 500px);
}

.logo {
  margin-right: auto;
}

.logo img {
  width: clamp(9rem, 20vw, 13rem);
  height: auto;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

.logo img:hover {
  transform: scale(1.03);
}

.pages {
  display: flex;
  flex-wrap: nowrap;
  gap: 2vw;
  list-style: none;
  padding-top: 0.4rem;
}

.pages li,
.hum_pages li {
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

.pages li:hover,
.hum_pages li:hover {
  transform: scale(1.1);
}

.pages a,
.hum_pages a {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-size: clamp(0.8rem, 1.2vw, 1.05rem);
  font-weight: 700;
  letter-spacing: 0.5px;
}

.pages a:global(.active),
.hum_pages a:global(.active) {
  color: #01b2ff;
}

.pages .call:hover,
.hum_pages .hum_call:hover {
  transform: scale(1.05);
}

.call a,
.hum_call a {
  border: 0.18rem solid black;
  padding: 0.3rem 0.7rem;
  border-radius: 0.7rem;
  color: black;
}

.call a:global(.active),
.hum_call a:global(.active) {
  border-color: #01b2ff;
}

.humburger {
  display: none;
}

/* Sidebar */
.hum_pages {
  position: absolute;
  height: 100vh;
  z-index: 1;
  top: clamp(65px, 4.5vw, 300px);
  left: 0;
  right: 0;
  padding: 5vh clamp(15px, 7vw, 500px);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  gap: 4vh;
  list-style: none;
}

@media screen and (max-width: 900px) {
  .page {
    display: none;
  }

  .humburger {
    padding: 2px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.1vw;
    margin-left: 2vw;
    border: none;
    background: none;
    width: 21px;
    height: 21px;
    outline: none;
    cursor: pointer;
  }

  .humburger span {
    width: 100%;
    height: 3.5px;
    background-color: black;
    border-radius: 3px;
    will-change: transform;
  }
}
@media screen and (max-width: 600px) {
  .pages {
    display: none;
  }

  .humburger {
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .pages {
    display: none;
  }
}
