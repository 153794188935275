.contact_us {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 30rem;
  width: 97vw;
  margin-inline: auto;
  background: url("../../assets/svg/bg-text.svg") top 20px right 20px no-repeat,
    url("../../assets/svg/bg-logo.svg") bottom left no-repeat;
  background-size: 40%, 40%;
}

.button {
  text-decoration: none;
}
