.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-inline: auto;
  margin-top: 7vw;
  margin-bottom: 1vw;
  background-color: black;
  color: rgba(217, 217, 217, 1);
  height: 20rem;
  width: 97vw;
  border-radius: 1.5rem;
  overflow: hidden;
}

.links {
  display: flex;
  gap: 1rem;
}

.links img,
.links button {
  width: 1.7rem;
  height: 1.7rem;
}

.links button {
  border-radius: 50%;
  border: none;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

.links button:hover {
  transform: scale(1.1);
}

.logo_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.about {
  font-size: 1.6rem;
  letter-spacing: 0.7rem;
  text-transform: uppercase;
  text-align: center;
}

.contacts {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-top: -2rem;
}

.contacts span::before {
  content: "/";
  margin-inline: 0.5rem;
}

.logo {
  margin-inline: auto;
  width: 8rem;
  height: auto;
}

.rights {
  margin-top: 0.3rem;
  padding-left: 0.3rem;
  text-align: center;
  font-size: 0.6rem;
}

@media (max-width: 900px) {
  .about {
    font-size: 1.4rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-align: center;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
    margin-top: 0;
    gap: 0.1rem;
  }
  .about span {
    display: block;
  }

  .contacts span {
    display: block;
  }

  .contacts span::before {
    content: none;
  }
}

@media (max-width: 600px) {
  .about {
    font-size: 1.1rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    text-align: center;
  }

  .contacts {
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
  }
}
