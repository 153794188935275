@font-face {
  font-family: "Geist Variable";
  src: url('./assets/fonts/GeistVariableVF.ttf') format('truetype');
}

* {
  font-family: 'Geist Variable';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
    margin-inline: auto;
    overflow-x: hidden;
}

main {
  position: relative;
  top: calc(6vw + 20px);
}