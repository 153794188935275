.main {
  margin-top: 1rem;
  max-width: 50rem;
  margin-inline: auto;
  min-height: 70vh;
  margin-bottom: calc(8rem + 3vw);
}

.main h1 {
  font-weight: 400;
  font-size: 4rem;
  text-align: left;
  white-space: nowrap;
}

.new_line {
  display: block;
}

.blue {
  font-weight: 700;
  color: rgba(1, 178, 255, 1);
}

.steps {
  max-width: 100%;
  margin-top: 3rem;
  display: flex;
}

.above,
.below {
  display: flex;
  max-width: 50%;
}

@media screen and (max-width: 950px) {
  .main {
    margin-inline: clamp(15px, 7vw, 500px);
  }

  .main h1 {
    font-size: 3.2rem;
    padding-right: 5rem;
  }
  .steps {
    flex-direction: column;
    gap: 1.5rem;
  }

  .above,
  .below {
    max-width: 100%;
    justify-content: space-between;
    padding-right: 5rem;
  }
}

@media screen and (max-width: 750px) {
  .main h1 {
    font-size: 2.6rem;
    padding-right: 0;
    white-space: normal;
  }
}

@media screen and (max-width: 650px) {
  .new_line {
    display: inline;
  }
  .steps {
    margin-top: 2rem;
    gap: 1rem;
  }

  .above,
  .below {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    gap: 1rem;
  }
}

@media screen and (max-width: 530px) {
  .main h1 {
    font-size: 2.1rem;
  }
}
