.main {
  margin-top: 1rem;
  max-width: 50rem;
  margin-inline: auto;
  min-height: 70vh;
  margin-bottom: calc(8rem + 3vw);
}
.main h1 {
  font-weight: 400;
  font-size: 4rem;
  text-align: left;
}

.blue {
  font-weight: 700;
  color: rgba(1, 178, 255, 1);
}

.section {
  width: 100%;
  display: flex;
  margin-top: 3rem;
  gap: 3rem;
}

.section p {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: justify;
  margin-bottom: 1rem;
}

.left,
.right {
  max-width: 23.3rem;
  flex: 1;
}

.section .sign {
  color: rgba(1, 178, 255, 1);
  text-align: right;
  margin-top: 2rem;
}

@media screen and (max-width: 950px) {
  .main {
    margin-inline: clamp(15px, 7vw, 500px);
  }

  .main h1 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 750px) {
  .main h1 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 650px) {
  .main h1 {
    font-size: 2rem;
    max-width: 29rem;
    margin-inline: auto;
  }

  .section {
    margin-top: 2rem;
    flex-direction: column;
    gap: 0;
  }

  .left,
  .right {
    margin-inline: auto;
    max-width: 29rem;
  }
}
