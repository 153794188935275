.chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 16rem;
  width: calc(16rem + 15vw);
}

.chat p {
  color: white;
  font-size: clamp(1.2rem, 2vw, 1.4rem);
  font-weight: 500;
  text-wrap: nowrap;
  padding: 0.8rem 1.4rem;
  background-color: rgba(1, 178, 255, 1);
  border-radius: 2rem;
}

.chat .text_one,
.chat .text_two {
  margin-right: auto;
  border-top-left-radius: 0;
}

.chat .our_text {
  background-color: rgba(0, 0, 0, 1);
  text-align: right;
  border-bottom-right-radius: 0;
  margin: auto 0 0 auto;
}
